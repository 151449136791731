import React, { useState, useRef, useEffect } from "react";
import { MapContainer,TileLayer, LayersControl,GeoJSON, FeatureGroup, Marker,Popup} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { LatLng } from "leaflet";
import { useLocation } from "react-router-dom";
import {Typography,Box,Grid,Paper,Button,Table,TableBody,TableCell,TableRow,TableHead} from "@mui/material";
import { DOMParser } from "xmldom";
import { EditControl } from "react-leaflet-draw";
import OSMDataLayer from "./OSMDataLayer";
import nextId, { resetId } from "react-id-generator";

// Ensure Leaflet icons load correctly
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const DisplayMap = () => {
  const location = useLocation();
  const { filename, fileContent, fullfileContent } = location.state;
  const [updatedGeoJSON, setUpdatedGeoJSON] = useState(
    !filename.endsWith(".osm") ? JSON.parse(fullfileContent) : null
  );
  const [markers, setMarkers] = useState([]);
  const [popupContents, setPopupContents] = useState({});
  const featureGroupRef = useRef();

  // Initialize map center coordinates based on file content
  let lati, longi;

  if (filename.endsWith(".osm") && fileContent) {
    const parser = new DOMParser();
    const xml = parser.parseFromString(fileContent, "application/xml");
    const nodes = xml.getElementsByTagName("node");
    if (nodes.length > 0) {
      const firstNode = nodes[0];
      lati = firstNode.getAttribute("lat");
      longi = firstNode.getAttribute("lon");
    }
  }
  else if(filename.endsWith(".kml") && fileContent){
    lati = fileContent.latitude; 
    longi = fileContent.longitude; 
    console.log(fullfileContent);
    console.log(lati, longi, "from display map");
  }
   else {
    const temp = JSON.parse(fileContent);

    lati = temp.coordinates[0][1];
    longi = temp.coordinates[0][0];

    if (!lati && !longi) {
      lati = temp.features[0].geometry.coordinates[0][1];
      longi = temp.features[0].geometry.coordinates[0][0];
    }
  }

  const center = new LatLng(lati, longi);

  useEffect(() => {
    if(filename.endsWith(".kml")) setUpdatedGeoJSON(JSON.parse(fullfileContent));
    else if (!filename.endsWith(".osm")) setUpdatedGeoJSON(JSON.parse(fileContent));
  }, [fileContent,fullfileContent, filename]);

  // Handle marker click to display popup
  const handleMarkerClick = (markerId) => {
    setPopupContents((prevPopupContents) => ({
      ...prevPopupContents,
      [markerId]: prevPopupContents[markerId] || [{ key: "", value: "" }],
    }));
  };

  // Add a new row to popup content for a marker
  const handleAddRow = (markerId) => {
    setPopupContents((prevPopupContents) => ({
      ...prevPopupContents,
      [markerId]: [...prevPopupContents[markerId], { key: "", value: "" }],
    }));
  };

  // Save edited marker properties and update GeoJSON
  const handleSave = (markerId) => {
    const marker = markers.find((m) => m.markerId === markerId);
    if (marker) {
      const updatedMarkers = markers.map((m) =>
        m.markerId === markerId
          ? { ...m, properties: popupContents[markerId] }
          : m
      );

      setMarkers(updatedMarkers);

      const newFeatures = updatedMarkers.map((marker) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [marker.lng, marker.lat],
        },
        id: marker.markerId,
        properties: marker.properties.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {}),
      }));

      const fulljsonData = JSON.parse(fileContent);
      
      const lineStringFeature = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: fulljsonData.coordinates,
        },
      };

      const updatedGeoJSONFeatureCollection = {
        type: "FeatureCollection",
        features: [lineStringFeature, ...newFeatures],
      };

      setUpdatedGeoJSON(updatedGeoJSONFeatureCollection);
    }
  };

  // Export updated GeoJSON to a file
  const handleExport = () => {
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(updatedGeoJSON));
    const filename = "webmap___" + Date.now() + ".geojson";
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    resetId();
  };

  // Handle creation of new marker
  const onCreated = (e) => {
    const { layerType, layer } = e;
    if (layerType === "marker") {
      console.log(layerType);
      const markerCoords = layer.getLatLng();
      const markerId = nextId()[2];
      console.log("marker", markerId);
      const newMarker = {
        markerId: markerId,
        lat: markerCoords.lat,
        lng: markerCoords.lng,
        properties: [],
      };
      setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
      setPopupContents((prevPopupContents) => ({
        ...prevPopupContents,
        [markerId]: [],
      }));
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography
        variant="h3"
        sx={{ mb: 2, fontStyle: "italic", fontWeight: "bold" }}
      >
        {`${filename.split(".")[0]} Map`}
      </Typography>
      {filename.endsWith(".osm") && fileContent ? (
        <OSMDataLayer data={fileContent} geojsondata={fullfileContent} lati={lati} longi={longi} />
      ) : (
        <Grid container spacing={4} sx={{ mb: 5 }}>
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 1, height: "80vh" }}>
              <MapContainer
                center={center}
                zoom={18}
                scrollWheelZoom={true}
                style={{ height: "100%", width: "100%" }}
              >
                <LayersControl>
                  <LayersControl.BaseLayer checked name="Satellite View">
                    <TileLayer
                      url="http://{s}.google.com/vt?lyrs=s&x={x}&y={y}&z={z}"
                      maxZoom={30}
                      subdomains={["mt1", "mt2", "mt3"]}
                    />
                  </LayersControl.BaseLayer>
                  <FeatureGroup ref={featureGroupRef}>
                    {fullfileContent && (
                      <EditControl
                        position="topright"
                        onCreated={onCreated}
                        onEdited={(e) => {
                          const { layers } = e;
                          layers.eachLayer(function (layer) {
                            console.log(layer);
                          });
                          setUpdatedGeoJSON(layers.toGeoJSON());
                        }}
                        onDeleted={(e) => {
                          const { layers } = e;
                          setUpdatedGeoJSON(layers.toGeoJSON());
                        }}
                        edit={{
                          featureGroup: featureGroupRef.current,
                        }}
                      />
                    )}
                    <GeoJSON
                      ref={featureGroupRef}
                      data={updatedGeoJSON}
                      style={() => ({
                        color: "#02f527",
                        weight: 3,
                        opacity: 0.9,
                      })}
                    />
                    {markers.map((marker) => (
                      <Marker
                        key={marker.markerId}
                        position={[marker.lat, marker.lng]}
                        eventHandlers={{
                          click: () => {
                            handleMarkerClick(marker.markerId);
                          },
                        }}
                      >
                        <Popup>
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Key</TableCell>
                                  <TableCell>Value</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(popupContents[marker.markerId] || []).map(
                                  (row, index) => (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <input
                                          type="text"
                                          placeholder="Key"
                                          value={row.key}
                                          onChange={(e) => {
                                            const newContent = [
                                              ...popupContents[marker.markerId],
                                            ];
                                            newContent[index].key =
                                              e.target.value;
                                            setPopupContents(
                                              (prevContents) => ({
                                                ...prevContents,
                                                [marker.markerId]: newContent,
                                              })
                                            );
                                          }}
                                          sx={{ width: "50%" }}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <input
                                          type="number"
                                          placeholder="Value"
                                          value={row.value}
                                          onChange={(e) => {
                                            const newContent = [
                                              ...popupContents[marker.markerId],
                                            ];
                                            newContent[index].value = parseInt(
                                              e.target.value,
                                              10
                                            );
                                            setPopupContents(
                                              (prevContents) => ({
                                                ...prevContents,
                                                [marker.markerId]: newContent,
                                              })
                                            );
                                          }}
                                          style={{ height:"40px",width: "60px",borderRadius:"5px" }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                            <Button
                              onClick={() => handleAddRow(marker.markerId)}
                            >
                              Add Row
                            </Button>
                            <Button
                              onClick={() => handleSave(marker.markerId)}
                              sx={{ bgcolor: "green" }}
                            >
                              Save
                            </Button>
                            <Button sx={{ bgcolor: "red" }}>Cancel</Button>
                          </div>
                        </Popup>
                      </Marker>
                    ))}
                  </FeatureGroup>
                </LayersControl>
              </MapContainer>
              <Button
                onClick={handleExport}
                sx={{
                  bgcolor: "lightgreen",
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  zIndex: 1000,
                }}
              >
                Export GeoJSON
              </Button>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DisplayMap;

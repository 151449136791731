import React, { useRef, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import BatteryGauge from "react-battery-gauge";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-car.css";
// import { styled } from '@mui/system';

// const StyledOdometerBox = styled(Box)(({ theme }) => ({
//   '& .odometer.odometer-auto-theme, & .odometer.odometer-theme-car': {
//     display: 'inline-block',
//     verticalAlign: 'middle',
//     position: 'relative',
//     border: "2px solid black",
//     borderRadius: '0.34em',
//     fontFamily: '"Arimo", monospace',
//     padding: '0.15em',
//     background: '#000',
//     color: '#eee0d3',
//   },
//   '& .odometer .odometer-digit': {
//     display: 'inline-block',
//     verticalAlign: 'middle',
//     position: 'relative',
//     boxShadow: 'inset 0 0 0.3em rgba(0, 0, 0, 0.8)',
//     background: 'linear-gradient(to bottom, #333333 0%, #333333 40%, #101010 60%, #333333 80%, #333333 100%)',
//     padding: '0 0.15em',
//   },
//   '& .odometer .odometer-digit:first-of-type': {
//     borderRadius: '0.2em 0 0 0.2em',
//   },
//   '& .odometer .odometer-digit:last-child': {
//     borderRadius: '0 0.2em 0.2em 0',
//     backgroundImage: 'linear-gradient(to bottom, #eee0d3 0%, #eee0d3 40%, #bbaa9a 60%, #eee0d3 80%, #eee0d3 100%)',
//     backgroundColor: '#eee0d3',
//     color: '#000',
//   },
//   '& .odometer .odometer-digit .odometer-digit-inner': {
//     left: '0.15em',
//   },
//   '& .odometer.odometer-animating-up .odometer-ribbon-inner, & .odometer.odometer-animating-down.odometer-animating .odometer-ribbon-inner': {
//     transition: 'transform 2s',
//     transitionTimingFunction: 'linear',
//   },
// }));

const BatteryBox = ({ title, icon, value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const odometerRef = useRef(null);

  useEffect(() => {
    if (odometerRef.current && title === "DistanceTraveled(kms)") {
      odometerRef.current.update(parseInt(value));
    }
  }, [value, title]);

  return (
    <Box
      width="100%"
      m="0 30px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="70%"
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {title}
        </Typography>
        <Box mb="22px">
          {title === "DistanceTraveled(kms)" ? (
            <Box sx={{ width: "100%", maxWidth: "300px", fontSize: "26px", mt:"10px"}}>
              <Odometer
                value={value}
                format="(,ddd).dd"
                options={{
                  auto: false, // Don't automatically initialize everything with class 'odometer'
                  selector: ".my-numbers", // Change the selector used to automatically find things to be animated
                  format: "(,ddd).dd", // Change how digit groups are formatted, and how many digits are shown after the decimal point
                  duration: 3000, // Change how long the javascript expects the CSS animation to take
                  theme: "car", // Specify the theme (if you have more than one theme css file on the page)
                  animation: "count",
                }}
              />
            </Box>
          ) : (
            <BatteryGauge
              customization={
                title === "Battery SOH"
                  ? {
                      batteryMeter: {
                        noOfCells: 5,
                      },
                    }
                  : {}
              }
              value={value}
              size={150}
              animated={true}
              padding={5}
            />
          )}
        </Box>
      </Box>
      {icon && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="30%"
        >
          {icon}
        </Box>
      )}
    </Box>
  );
};

export default BatteryBox;

import React, { useCallback, useEffect, useState, useRef } from "react";
import { MapContainer, Marker, TileLayer, LayersControl, GeoJSON, useMap, Popup, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLng } from "leaflet";
import { Typography, Box, Grid, Paper, Autocomplete, TextField, List, ListItem, Button } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { Icon } from "leaflet";

const ChangeView = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center) {
      map.setView(center, 21);
    }
  }, [center, map]);
  return null;
};

const LiveVehicleLocation = () => {
  const tileLayerUrls = {
    googleStreets: "http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
    googleSatellite: "http://{s}.google.com/vt?lyrs=s&x={x}&y={y}&z={z}",
    googleHybrid: "http://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}",
    googleTerrain: "http://{s}.google.com/vt?lyrs=p&x={x}&y={y}&z={z}",
  };

  const vehicle = JSON.parse(localStorage.getItem("vehicle"));
  const VehicleId = localStorage.getItem("VehicleId");
  const onlineStatus = localStorage.getItem("onlineStatus");
  const lastOnlineTime = localStorage.getItem("lastOnlineTime");
  const [jsonData, setJsonData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("last1Hour");
  const [center, setCenter] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [isLive, setisLive] = useState(false); 
  const [path, setPath] = useState([]);  // Array to store past coordinates
  const eventSourceRef = useRef(null);   // Ref to track EventSource
  const API_URL = process.env.REACT_APP_API_URL;

  const options = [
    "last1Hour",
    "last2Hours",
    "last3Hours",
    "last4Hours",
    "last5Hours",
    "last6Hours",
    "last1Day",
    "last2Days",
    "last3Days",
    "last4Days",
    "last5Days",
  ];

  const getCoordinates = useCallback(async () => {
    try {
      console.log(selectedOption + " from GPS coordinates");
      const res = await axios.get(`${API_URL}/${selectedOption}/${VehicleId}`);
      if (res.data === "No data") {
        toast.warn(res.data);
        setJsonData(null);
        setCenter(null);
        return;
      }
      console.log(res.data);
      setJsonData(res.data);

      const lat = res.data.coordinates[res.data.coordinates.length - 1][1];
      const lng = res.data.coordinates[res.data.coordinates.length - 1][0];

      if (lat !== undefined && lng !== undefined) {
        setCenter(new LatLng(lat, lng));
        setCoordinates({ lat, lng });
        setPath((prevPath) => [...prevPath, [lat, lng]]);  // Add last coordinate to path
      } else {
        toast.error("Invalid coordinates received");
        setCenter(null);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error when fetching the status...");
    }
  }, [selectedOption, VehicleId, API_URL]);

  const handleChange = (event, value) => {
    setSelectedOption(value);
  };
  
  const stopLive = ()=>{
     console.log("vehicle stopped");
     setisLive(false); 
     if (eventSourceRef.current) {
      eventSourceRef.current.close(); // Close any existing EventSource to prevent duplicates
      fetch(`${API_URL}/stoplive/${VehicleId}`, { method: 'POST' });
      setPath([]);  // Clear the path on exit
    }
  }


  const goLive = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close(); // Close any existing EventSource to prevent duplicates
      fetch(`${API_URL}/stoplive/${VehicleId}`, { method: 'POST' });
      setPath([]);  // Clear the path on exit
    }
      setisLive(true); 
      eventSourceRef.current = new EventSource(`${API_URL}/golive/${VehicleId}`);
      eventSourceRef.current.onmessage = (event) => {
      if (event.data) {
        const data = JSON.parse(event.data);
        console.log(data);

        if (data.latitude!== null && data.longitude !== null && data.latitude !== undefined && data.longitude !== undefined) {
          setCoordinates({ lat: data.latitude, lng: data.longitude });
          setCenter(new LatLng(data.latitude, data.longitude));
          setPath((prevPath) => [...prevPath, [data.latitude, data.longitude]]); // Add to path
        } else {
          toast.warn("Fetching the Live Coordinates from the vehicle");
        }
      }
     };

    return () => {
      eventSourceRef.current?.close();  // Close EventSource on unmount
      fetch(`${API_URL}/stoplive/${VehicleId}`, { method: 'POST' });
      setPath([]);  // Clear the path on exit
    };
 };

  useEffect(() => {
    getCoordinates();
  }, [VehicleId, selectedOption, getCoordinates]);

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={4} alignItems="center" sx={{ mb: 3 }}>
        <Grid item xs={12} md={6} display="flex" justifyContent="flex-start">
          <Typography variant="h3" sx={{ mb: 2, fontStyle: "italic" }}>
            {`${vehicle.label} Live Location`}
          </Typography>
          {!isLive ? (<Grid item xs={12} md={6} display="flex" justifyContent="flex-start" marginLeft={5}>
            <Button variant="contained" onClick={goLive} sx={{ bgcolor: "green", width: "100px", height: "35px", fontSize: "15px", "&:hover": {bgcolor: "#12ad03"}}}>Go Live</Button>
          </Grid> ):
             (
              <Grid item xs={12} md={6} display="flex" justifyContent="flex-start" marginLeft={5}>
            <Button variant="contained" onClick={stopLive} sx={{ bgcolor: "red", width: "100px", height: "35px", fontSize: "13px", "&:hover": {bgcolor: "#eb240e"} }}>Stop Live</Button>
          </Grid>
             )   
          }
        </Grid>

        <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
          <Autocomplete
            options={options}
            value={selectedOption}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField {...params} label="Time" variant="outlined" />
            )}
            style={{ width: "300px", height: "40px" }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 1, height: "75vh" }}>
            {center && (
              <MapContainer
                center={center}
                zoom={21}
                scrollWheelZoom={true}
                style={{ height: "100%", width: "100%" }}
              >
                <ChangeView center={center} />
                <LayersControl>
                  <LayersControl.BaseLayer checked name="Satellite View">
                    <TileLayer
                      url={tileLayerUrls.googleSatellite}
                      maxZoom={30}
                      subdomains={["mt1", "mt2", "mt3"]}
                    />
                  </LayersControl.BaseLayer>

                  <LayersControl.BaseLayer name="Hybrid View">
                    <TileLayer
                      url={tileLayerUrls.googleHybrid}
                      maxZoom={30}
                      subdomains={["mt0", "mt1", "mt2", "mt3"]}
                    />
                  </LayersControl.BaseLayer>

                  {jsonData && (
                    <GeoJSON
                      data={jsonData}
                      key={JSON.stringify(jsonData)} // This ensures GeoJSON re-renders when data changes
                      style={() => ({
                        color: "yellow",
                        weight: 3,
                        opacity: 0.7,
                      })}
                    />
                  )}
                  {coordinates && (
                    <Marker
                      position={coordinates}
                      icon={
                        new Icon({
                          iconUrl: "/img/blackdrag.png",
                          iconSize: [40, 30],
                        })
                      }
                    >
                      <Popup position={coordinates}>
                        <Box>
                          <Typography variant="h6" fontStyle="italic">
                            <img
                              src="https://bosonmotors.com/wp-content/uploads/2022/06/logo.png"
                              alt="boson"
                              sx={{ height: "50px", width: "50px" }}
                            />{" "}
                            AUTONOMOUS TRUCK INFO
                          </Typography>
                          <List>
                            <ListItem>VehicleId: {VehicleId}</ListItem>
                            <ListItem>VehicleName: {vehicle.label}</ListItem>
                            <ListItem>OnlineStatus: {onlineStatus}</ListItem>
                            {onlineStatus === "Offline" && (
                              <ListItem>
                                Last Online Time: {lastOnlineTime}
                              </ListItem>
                            )}
                          </List>
                        </Box>
                      </Popup>
                    </Marker>
                  )}
                  {/* Display the path (tail) using Polyline */}
                 { path.length > 1 && <Polyline positions={path} color="blue" />}
                </LayersControl>
              </MapContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LiveVehicleLocation;

